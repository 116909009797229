import styles from './ProfileIcon.module.css';
export const ProfileIcon = () => {
	return (
		<div className={styles.container}>
			<svg
				width="16"
				height="21"
				viewBox="0 0 16 21"
				fill="#303030"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8 0.25C5.37665 0.25 3.25 2.37665 3.25 5C3.25 7.62335 5.37665 9.75 8 9.75C10.6234 9.75 12.75 7.62335 12.75 5C12.75 2.37665 10.6234 0.25 8 0.25ZM4.75 5C4.75 3.20507 6.20507 1.75 8 1.75C9.79493 1.75 11.25 3.20507 11.25 5C11.25 6.79493 9.79493 8.25 8 8.25C6.20507 8.25 4.75 6.79493 4.75 5Z"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M8 11.25C5.96067 11.25 4.07752 11.7208 2.67815 12.5204C1.3 13.3079 0.25 14.5101 0.25 16C0.25 17.4899 1.3 18.6921 2.67815 19.4796C4.07752 20.2792 5.96067 20.75 8 20.75C10.0393 20.75 11.9225 20.2792 13.3219 19.4796C14.7 18.6921 15.75 17.4899 15.75 16C15.75 14.5101 14.7 13.3079 13.3219 12.5204C11.9225 11.7208 10.0393 11.25 8 11.25ZM1.75 16C1.75 15.2807 2.26701 14.483 3.42236 13.8228C4.55649 13.1747 6.17334 12.75 8 12.75C9.82666 12.75 11.4435 13.1747 12.5776 13.8228C13.733 14.483 14.25 15.2807 14.25 16C14.25 16.7193 13.733 17.517 12.5776 18.1772C11.4435 18.8253 9.82666 19.25 8 19.25C6.17334 19.25 4.55649 18.8253 3.42236 18.1772C2.26701 17.517 1.75 16.7193 1.75 16Z"
				/>
			</svg>
		</div>
	);
};
